export const system = 12;

export const systemTitle = '年报通' //'生活'//

const appInfo = [
  {
    appName: "怡信",
    telephone: "18973819131",
    mpUrl: "https://51w.co/chhOl",
    company: "娄底怡信信息咨询有限公司",
    icp: "湘ICP备2023010289号-1",
    useTitle: true
  },//? 0
  {
    appName: "千里",
    telephone: "13142239783",
    mpUrl: "https://z.9i5.cc/0000",
    company: "湖南志在千里科技有限公司",
    icp: "湘ICP备2023025621号-2",
    useTitle: true
  },//? 1
  {
    appName: "凭信",
    telephone: "13751262079",
    mpUrl: "https://w.9i5.cc/0000",
    company: "湖南凭信网络科技有限公司",
    icp: "湘ICP备2023025693号-2",
    useTitle: true
  },//? 2
  {
    appName: "佐信",
    telephone: "13874835030",
    mpUrl: "https://m.z8x.co/0000",
    company: "湖南省佐信科技有限公司",
    icp: "湘ICP备2023030643号-2",
    h5Url: 'https://z8x.co/h5',
    useTitle: true
  },//? 3
  {
    appName: "晨希",
    telephone: "15773816683",
    mpUrl: "https://m.d9x.co/0000",
    company: "娄底市晨希网络科技有限公司",
    icp: "湘ICP备2023033409号",
    useTitle: true
  },//? 4
  {
    appName: "立腾",
    telephone: "18627619950",
    mpUrl: "https://m.lt8.co/0000",
    company: "娄底市立腾网络科技有限公司",
    icp: "湘ICP备2023031984号-2",
    h5Url: 'https://www.lt8.co/h5',
    time: '2024',
    useTitle: true
  },//? 5
  {
    appName: "弘祥",
    telephone: "13822156962",
    mpUrl: "https://m.h9x.co/0000",
    company: "长沙弘祥生物科技有限公司",
    icp: "湘ICP备2023039504号-2",
    time: '2024',
    logo: 'https://hx-1323651380.cos.ap-shanghai.myqcloud.com/logl.jpg',
    webLogoStyle: {
      left: '5%',
      top: '-50%',
      // transform: 'scale(1.4)',
      height: '5.6rem',
    },
    h5LogoStyle1: {

    },
    h5LogoStyle2: {

    }
  },//? 6
  {
    appName: "昀信",
    telephone: "13397381077",
    mpUrl: "https://m.yx3.co/0000",
    company: "湖南昀信科技有限公司",
    icp: "湘ICP备2024043471号-2",//"湘ICP备2023039504号-2",
    h5Url: 'https://yx3.co/h5',
    time: '2024',
    logo: 'logo8.jpg',
    webLogoStyle: {
      left: '15%',
      top: '-44%',
      transform: 'scale(1.4)',
      height: '5.6rem',
    },
    h5LogoStyle1: {

    },
    h5LogoStyle2: {

    }
  },//? 7
  {
    appName: "升瀚",
    telephone: "13397381077",
    // mpUrl: "https://m.yx3.co/0000",
    company: "娄底市娄星区速腾网络科技有限公司",
    icp: "湘ICP备2024084934号-1",//"湘ICP备2023039504号-2",
    h5Url: 'https://www.sut8.cn/h5',
    time: '2024',
    logo: 'logo9.png',
    webLogoStyle: {
      left: '36%',
      top: '-52%',
      transform: 'scale(1.4)',
      height: '5.6rem',
    },
    h5LogoStyle1: {

    },
    h5LogoStyle2: {

    }
  },//? 8
  {
    appName: "瑞信",
    telephone: "13874835030",
    // mpUrl: "https://m.yx3.co/0000",
    company: "湖南省瑞信通科技有限公司",
    icp: "湘ICP备2024054528号-1",//"湘ICP备2024084934号-1",//"湘ICP备2023039504号-2",
    h5Url: 'https://rxnbt.cn/h5',
    time: '2024',
    useTitle: true,
    logo: 'logo10.png',
    webLogoStyle: {
      left: '5%',
      top: '-68%',
      transform: 'scale(1.4)',
      height: '5.6rem',
    },
    h5LogoStyle1: {

    },
    h5LogoStyle2: {

    }
  },//? 9
  {
    appName: "捷信",
    telephone: "18673821314",
    // mpUrl: "https://m.yx3.co/0000",
    company: "湖南捷信通网络科技有限公司",
    icp: "湘ICP备2024043546号",//"湘ICP备2024084934号-1",//"湘ICP备2023039504号-2",
    h5Url: 'https://jiex8.cn/h5',
    time: '2024',
    useTitle: false,
    logo: 'logo11.png',
    webLogoStyle: {
      left: '5%',
      top: '-54%',
      transform: 'scale(2.5)',
      height: '5.6rem',
    },
    h5LogoStyle1: {
      left: '2.8rem',
      height: '100%',
      transform: 'scale(2)',
    },
    h5LogoStyle2: {
      height: '4.4rem',
      transform: 'scale(2)',
      top: '-1rem',
      left: '-4.0rem',
    },
  },//? 10
  {
    appName: "汇泰",
    telephone: "18673821314",
    // mpUrl: "https://m.yx3.co/0000",
    company: "娄底市经济技术开发区汇泰商务管理有限公司",
    icp: "湘ICP备2024090789号-1",//"湘ICP备2024084934号-1",//"湘ICP备2023039504号-2",
    h5Url: 'https://huit8.vip/h5',
    time: '2024',
    logo: 'logo12.png',
    webLogoStyle: {
      left: '20%',
      top: '-54%',
      transform: 'scale(2)',
      height: '5.6rem',
    },
    h5LogoStyle1: {
      height: '4.4rem',
      transform: 'scale(2)',
      top: '-1rem',
      left: '-4.0rem',
    },
    h5LogoStyle2: {

    },
  },//? 11
  {
    appName: "豪健",
    telephone: "18973133083",
    company: "湖南豪健网络科技有限公司",
    icp: "湘ICP备2024040020号-2",
    h5Url: 'https://hj6.co/h5',
    time: '2024',
    useTitle: true,
    useWebTitle: true,
    logo: 'https://hj-1323443561.cos.ap-shanghai.myqcloud.com/Upload/2024/01/02/15619177816901.png',
    webLogoStyle: {
      left: '14%',
      top: '-50%',
      transform: 'scale(0.6)',
      height: '5.6rem',
    },
    h5LogoStyle1: {
      height: '4.4rem',
      transform: 'scale(0.6)',
      top: '-0.3rem',
      left: '1.0rem',
    },
    h5LogoStyle2: {
      height: '4.4rem',
      transform: 'scale(0.6)',
      top: '-0.9rem',
      left: '-5.0rem',
    },
    navStyle: {
      marginLeft: '-40px'
    }
  },//? 12
];
export default appInfo
export let mapComputer = (item) => {
  let obj = {};
  for (var index in item) {
    let key = item[index];
    obj[key] = () => {
      return appInfo[system][key]
    }
  }
  return obj;
}