<template>
  <div class="page-container" v-if="clientWidth < 450">
    <nav-bar @hanldeNavClick="visible = true"></nav-bar>
    <div style="overflow-x: hidden; overflow-y: scroll; height: 90%">
      <b-jumbotron fluid class="mt-3" bg-variant="drak" text-variant="black"
        style="margin-bottom: 2rem; position: relative" id="nav">
        <div class="app-title">
          {{ appName }}{{ system === 0 ? "企业" : "" }}{{ systemTitle }}
        </div>
        <p class="introduce-text1">企业年报代报服务</p>
        <p class="introduce-text2">
          选择{{ appName
          }}{{
  system === 0 ? "企业" : ""
}}{{ systemTitle }}，享受快速申报，省时、省事、省力
        </p>
        <div v-if="h5Url" style="z-index: 10; position: relative; display: flex">
          <b-button class="button" variant="primary" :href="h5Url">快速代申报</b-button>
          <b-button class="button" variant="primary" style="background-color: #12c554;border-color: #12c554;"
            :href="h5Url + '/#/pages_D/detail/index'">订单查询</b-button>
          <b-button v-if="!mpUrl" class="button" style="" :href="'tel:' + telephone">在线咨询</b-button>
          <!--
          <b-button class="button" style="" :href="'tel:' + telephone">小程序申报</b-button> -->
        </div>
        <div style="z-index: 10; position: relative; display: maring-top:10px;top:10px" v-if="mpUrl">
          <b-button class="button" variant="primary" :href="mpUrl">小程序申报</b-button>
          <b-button class="button" :href="'tel:' + telephone">在线咨询</b-button>
        </div>

        <b-img v-if="system === 0" src="
        base.png" fluid alt="Fluid image" style="position: absolute; left: -0.5rem; bottom: 0">
        </b-img>
        <b-img v-if="system === 1" src="
          ql.jpg" fluid alt="Fluid image" style="
            position: absolute;
            left: -0.5rem;
            bottom: 0;
            background-color: transparent;
          ">
        </b-img>
        <b-img v-if="system === 2" src="
          px.jpg" fluid alt="Fluid image" style="position: absolute; left: -0.5rem; bottom: 0">
        </b-img>
        <b-img v-if="system === 3" src="
          zx.jpg" fluid alt="Fluid image" style="position: absolute; left: -0.5rem; bottom: 0">
        </b-img>
        <b-img v-if="system === 4" src="
          cx.png" fluid alt="Fluid image" style="position: absolute; left: -0.5rem; bottom: 0">
        </b-img>
        <b-img v-if="system === 5" src="
          lt.png" fluid alt="Fluid image" style="position: absolute; left: -0.5rem; bottom: 0">
        </b-img>
        <b-img v-if="system === 6" src="
          hx.png" fluid alt="Fluid image" style="position: absolute; left: -0.5rem; bottom: 0">
        </b-img>
        <b-img v-if="system === 7" src="
          yx.png" fluid alt="Fluid image" style="position: absolute; left: -0.5rem; bottom: 0">
        </b-img>
        <b-img v-if="system === 8" src="
          yx.png" fluid alt="Fluid image" style="position: absolute; left: -0.5rem; bottom: 0">
        </b-img>
        <!-- <div class="bg-image01">
          <div class="phone-graphic">
            <p class="text1">{{appName}}{{system===0?"企业":""}}{{ systemTitle }}</p>
            <br />
            <div class="text2">请打开微信，访问{{appName}}{{systemTitle}}小程序</div>
            <br />
            <p class="text3">了解年报报送</p>
          </div>
        </div> -->
      </b-jumbotron>
      <b-container>
        <!-- 使用 .order -类控制内容的视觉顺序 -->
        <b-row align-v="center" align-h="center" class="mb-5">
          <b-col cols="12" md="4" mb="1" order-md="2" class="mt-3">
            <h4 class="text-sm-center text-md-left text-center" style="position: relative; font-weight: 600">
              产品简介
              <div class="virtua-anchor-point" style="position: absolute; top: -4rem" id="brie_introduction"></div>
            </h4>
            <h5 class="text-sm-center text-md-left text-center" style="font-weight: 600; font-family: 宋体">
              {{ appName }}{{ system === 0 ? "企业" : "" }}{{ systemTitle }}
            </h5>
            <p class="text-sm-center text-md-left" style="font-size: 0.8rem; line-height: 1.6rem">
              {{ appName }}{{ system === 0 ? "企业" : "" }}{{
                systemTitle
              }}是一个服务中小微企业、个体工商户等经营主体，为其提供年报代报、移出经营异常服务的平台，基于【操作简单】和【报送便捷】的服务理念，让经营主体更好的履行年报义务，不因年报未报或迟报而形成失信记录；为被标记经营异常的主体代办移出异常服务，修复经营主体信用，共同营造良好的社会信用环境。
            </p>
            <!-- <div class="text-sm-center text-md-left text-center" mb="1">
            <b-button variant="primary">
              <span class="mr-3">现在开始 </span>
              <b-icon icon="arrow-left" animation="cylon"></b-icon>
            </b-button>
          </div> -->
          </b-col>
          <b-col cols="12" md="8" mb="1" order-md="1">
            <div class="rounded-lg" style="background: linear-gradient(#3eb9de, #3a80de)">
              <b-img src="1.jpg" fluid alt="Fluid image" style="">
              </b-img>
            </div>
          </b-col>
        </b-row>
        <!-- 表格区域分割线 -->
        <b-row align-v="center" align-h="around">
          <b-col cols="12" md="8" order-md="2" class="mt-3">
            <h4 class="text-sm-center text-md-left text-center" style="font-weight: 600; position: relative">
              {{ appName }}{{ system === 0 ? "企业" : "" }}{{ systemTitle }}，快捷报送年报
              <div class="virtua-anchor-point" style="position: absolute; top: -4rem" id="submit"></div>
            </h4>
            <p class="text-sm-center text-md-left text-center" style="font-size: 0.8rem; line-height: 1.6rem">
              经营者可以通过手机随时随地报送年报，操作简单。报送便捷，更有专业客服全程服务，让您省时、省事、省力
            </p>
          </b-col>
          <b-col cols="12" md="4" order-md="5" style="position: relative">
            <!-- 换成0.png截图 -->
            <!-- <p
              style="position: absolute;width: 7.6rem;text-align: center;z-index:999999;font-size:0.6rem;color:rgba(255,255,255,0.84);top:10.4rem;left: 2.6rem;">
              微信搜索“{{ appName }}{{ system === 0 ? "企业" : "" }}年报通”小程序，咨询在线客服，开通年报代报服务。</p>
            <p
              style="position: absolute;width: 7.6rem;text-align: center;z-index:999999;font-size:0.6rem;color:rgba(255,255,255,0.84);bottom:7.8rem;right: 1rem;">
              {{ telephone | phoneFilter(' ') }}</p> -->
            <b-img v-if="system === 0" src="1.png" fluid alt="Fluid image"></b-img>
            <b-img v-if="system === 1" src="2.png" fluid alt="Fluid image"></b-img>
            <b-img v-if="system === 2" src="3.png" fluid alt="Fluid image"></b-img>
            <b-img v-if="system === 3" src="4.png" fluid alt="Fluid image"></b-img>
            <b-img v-if="system === 4" src="5.png" fluid alt="Fluid image"></b-img>
            <b-img v-if="system === 5" src="6.png" fluid alt="Fluid image"></b-img>
            <b-img v-if="system === 6" src="7.png" fluid alt="Fluid image"></b-img>
            <b-img v-if="system === 7" src="8.png" fluid alt="Fluid image"></b-img>
            <b-img v-if="system === 8" src="9.png" fluid alt="Fluid image"></b-img>
            <!-- <div style="height: 300px; background: linear-gradient(#696cbf, #e2859f)" class="rounded-lg"></div> -->
          </b-col>
        </b-row>
        <!-- 表格区域分割线 -->
        <!-- <b-row align-v="center" align-h="around" class="mb-5">
        <b-col cols="12" md="8" order="1" class="mt-3">
          <h4 class="text-sm-center text-md-left text-center" style="font-weight: 600;">
            经营异常移出
          </h4>
          <p class="text-sm-center text-md-left" style="font-weight: 600;font-family: 微软雅黑;">
            移除经营异常，修复企业信用
          </p>
          <p class="text-sm-center text-md-left">
            经营异常信息通过国家企业信用信息公示系统、信用中国及相关征信机构网站向社会公示，严重影响经营主体的信用形象。
            {{appName}}{{system===0?"企业":""}}{{systemTitle}}提供移除经营异常服务，助理企业修复信用形象，解除企业后顾之忧
          </p>
        </b-col>
        <b-col cols="12" md="4" order="2">
          <div class="rounded-lg" style="
              height: 200px;
              background: linear-gradient(to right, #3eb9de, #3a80de);
            "></div>
        </b-col>
      </b-row> -->

        <!-- 区域分割线 -->
        <!-- <b-row>
        <b-col>
          <h2 class="in-center">如何使用</h2>
          <p class="in-center">
            使用Trello—Copy的简单直观，你可以在几秒钟内从想法变成行动板子、列表和卡片。
          </p>
        </b-col>
      </b-row> -->
        <!-- 轮播图区域分割线  轮播图区域分割线轮播图区域分割线轮播图区域分割线轮播图区域分割线轮播图区域分割线轮播图区域分割线轮播图区域分割线-->
        <!-- <b-row align-h="around" class="mb-5">
        <b-col cols="12" md="8">
          <b-carousel :interval="4000" controls background="#ababab" img-width="1024" img-height="480"
            style="text-shadow: 1px 1px 2px">
            <b-carousel-slide caption="title1" img-src="0.jpg" img-alt="图片1">
              <p>默认显示</p>
            </b-carousel-slide>

            <b-carousel-slide caption="title2" img-src="1.jpg" img-alt="图片2">
              <p>默认显示</p>
            </b-carousel-slide>

            <b-carousel-slide caption="title3" img-src="2.jpg" img-alt="图片3">
              <p>默认显示</p>
            </b-carousel-slide>

            <b-carousel-slide caption="title4" img-src="0.jpg" img-alt="图片4">
              <p>默认显示</p>
            </b-carousel-slide>
          </b-carousel>
        </b-col>
      </b-row> -->

        <!-- 区域分割线 -->
        <!-- <b-row>
        <b-col>
          <h2 class="in-center">开启</h2>
          <p class="in-center">
            我们有灵活性和功能，以适应任何团队的风格。
          </p>
        </b-col>
      </b-row> -->

        <!-- 区域分割线 -->
        <!-- <b-row align-h="center" class="mb-5">
        <b-col cols="12" md="4" lg="4">
          <div class="in-center">
            <b-card title="Card1" img-src="0.jpg" img-alt="Image" img-top tag="article" class="mb-2">
              <b-card-text>
                <p>
                  我们已经收集你所需的所有板和工具作为一个有用的后背资源
                </p>
              </b-card-text>

              <b-button href="#" variant="primary">制作一个计划</b-button>
            </b-card>
          </div>
        </b-col>

        <b-col cols="12" md="4" lg="4">
          <div class="in-center">
            <b-card title="生产力平台" img-src="1.jpg" img-alt="Image" img-top tag="article" class="mb-2">
              <b-card-text>
                <p>
                  将您的团队已经使用的应用程序直接集成到您的工作流程中,以满足您团队的独特业务需求。
                </p>
              </b-card-text>
              <b-button href="#" variant="primary">Power-Up Your Workflow</b-button>
            </b-card>
          </div>
        </b-col>
        <b-col cols="12" md="4" lg="4">
          <div class="in-center">
            <b-card title="Card2" img-src="2.jpg" img-alt="Image" img-top tag="article" class="mb-2">
              <b-card-text>
                <p>
                  无论你在哪里，Trello都能在所有设备上保持同步。
                  在任何地方与你的团队合作，从坐在公交车上到坐在沙滩上。
                </p>
              </b-card-text>

              <b-button href="#" variant="secondary" class="mr-2">App Store</b-button>
              <b-button href="#" variant="secondary" style="margin-left:2rem">Google Play</b-button>
            </b-card>
          </div>
        </b-col>
      </b-row>-->
        <b-row class="mb-3" style="position: relative">
          <div class="virtua-anchor-point" style="position: absolute; top: -2rem" id="abnormal-operation"></div>
          <b-col cols="12" md="4" lg="4" style="padding: 0">
            <b-img src="yichang.0454e45c.png" fluid alt="Fluid image"></b-img>
          </b-col>
        </b-row>
        <b-row class="mb-3" id="application_scenarios">
          <b-col cols="12" md="4" lg="4" style="padding: 0">
            <b-img src="yingyong.812c0b32.png" fluid alt="Fluid image"></b-img>
          </b-col>
        </b-row>

        <!-- 区域分割线 -->
        <b-row style="margin-bottom: 0" id="contact_us">
          <b-col>
            <p style="font-size: 0.8rem; text-align: center; margin: 0">
              {{ appName
              }}{{ system === 0 ? "企业" : "" }}{{ systemTitle }}由国家中小企业公共服务示范平台
            </p>
            <!-- <p style="font-size: 0.8rem; text-align: center; margin: 0">
              国家中小企业公共服务示范平台
            </p> -->
            <p class="in-center" style="font-size: 0.8rem; margin: 0">
              {{ company }}运营 联系电话：{{ telephone | phoneFilter(" ") }}
            </p>
          </b-col>
        </b-row>
      </b-container>
      <MyFooter />
    </div>
    <div style="position: relative">
      <div style="
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 2rem;
          height: 100%;
          width: 100%;
        ">
        <a style="
            height: 60%;
            width: 30%;
            background-color: transparent;
            border: 0;
          " :href="h5Url"></a>
        <a style="
            height: 60%;
            width: 30%;
            background-color: transparent;
            border: 0;
          " :href="'tel:' + telephone"></a>
        <a style="
            height: 60%;
            width: 30%;
            background-color: transparent;
            border: 0;
          " :href="'tel:' + telephone"></a>
      </div>
      <b-img src="float.0e9bcedb.png" fluid alt="Fluid image"> </b-img>
    </div>

    <transition-group name="my">
      <div class="drawer" v-show="visible" :key="1">
        <transition name="my2">
          <div class="mask"></div>
        </transition>
        <div class="context">
          <div style="
              text-align: left;
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 0.6rem;
              padding-left: 4.6rem;
              font-size: 1.5rem;
            ">
            <div style="position: relative;height:100%;width:100%;margin-left: -20px;">
              <b-img src="logo.jpg" v-if="system === 0" style="
                  height: 5rem;
                  position: absolute;
                  z-index: 0;
                  top: -1.5rem;
                  left: -4.6rem;
                " fluid alt="Fluid image">
              </b-img>
              <b-img src="logo2.png" v-if="system === 1" style="
                  height: 4rem;
                  position: absolute;
                  z-index: 0;
                  top: -1rem;
                  left: -5rem;
                " fluid alt="Fluid image">
              </b-img>
              <b-img src="logo3.png" v-if="system === 2" style="
                  height: 5rem;
                  position: absolute;
                  z-index: 0;
                  top: -1rem;
                  left: -5.5rem;
                " fluid alt="Fluid image">
              </b-img>
              <b-img src="logo4.png" v-if="system === 3" style="
                  height: 5rem;
                  position: absolute;
                  z-index: 0;
                  top: -1rem;
                  left: -5.5rem;
                " fluid alt="Fluid image">
              </b-img>
              <b-img src="logo5.png" v-if="system === 4" style="
                  height: 4.4rem;
                  position: absolute;
                  z-index: 0;
                  top: -1rem;
                  left: -5.2rem;
                " fluid alt="Fluid image">
              </b-img>
              <b-img src="logo6.png" v-if="system === 5" style="
                  height: 4.4rem;
                  position: absolute;
                  z-index: 0;
                  top: -1rem;
                  left: -5.2rem;
                " fluid alt="Fluid image">
              </b-img>
              <b-img src="https://hx-1323651380.cos.ap-shanghai.myqcloud.com/logl.jpg" v-if="system === 6" style="
                  height: 4.4rem;
                  position: absolute;
                  z-index: 0;
                  top: -1rem;
                  left: -5.2rem;
                " fluid alt="Fluid image">
              </b-img>
              <b-img src="logo8.jpg" v-if="system === 7" style="
                  height: 4.4rem;
                  position: absolute;
                  z-index: 0;
                  top: -1rem;
                  left: -5.4rem;
                " fluid alt="Fluid image">
              </b-img>
              <b-img src="logo9.png" v-if="system === 8" style="
                  height: 4.4rem;
                  position: absolute;
                  z-index: 0;
                  top: -1rem;
                  left: -3.8rem;
                " fluid alt="Fluid image">
              </b-img>
              <b-img src="logo10.png" v-else-if="system === 9" style="
                  height: 4.4rem;
                  position: absolute;
                  z-index: 0;
                  top: -1rem;
                  left: -4.0rem;
                " fluid alt="Fluid image">
              </b-img>
              <!-- <b-img src="logo12.png" v-else-if="system === 11" style="
                  height: 4.4rem;
                  position: absolute;
                  z-index: 0;
                  top: -1rem;
                  left: -4.0rem;
                " fluid alt="Fluid image">
              </b-img> -->
              <b-img :src="logo" v-else style="
                  position: absolute;
                  z-index: 0;
                " :style="h5LogoStyle2" fluid alt="Fluid image">
              </b-img>
              <span v-if="useTitle" style="z-index: 10; position: relative;margin-left:0px; font-size: 20px">{{ system ===
                0 ? "企业" :
                appName }}{{ systemTitle }}</span>
            </div>
            <b-icon icon="x" style="font-size: 3px" @click="visible = false"></b-icon>
          </div>
          <b-list-group>
            <b-list-group-item><a :class="{ active: active === '#nav' }" @click.prevent="redirctTo('#nav')"
                href="#nav"><span class="iconfont">&#xe609;</span>首页</a></b-list-group-item>
            <b-list-group-item><a :class="{ active: active === '#brie_introduction' }"
                @click.prevent="redirctTo('#brie_introduction')" href="#brie_introduction"><span
                  class="iconfont">&#xe61e;</span>产品简介</a></b-list-group-item>
            <b-list-group-item><a :class="{ active: active === '#submit' }" @click.prevent="redirctTo('#submit')"
                href="#submit"><span class="iconfont">&#xe667;</span>报送年报</a></b-list-group-item>
            <b-list-group-item><a :class="{ active: active === '#abnormal-operation' }"
                @click.prevent="redirctTo('#abnormal-operation')" href="#abnormal-operation"><span
                  class="iconfont">&#xeb61;</span>经营异常移出</a></b-list-group-item>
            <b-list-group-item><a :class="{ active: active === '#application_scenarios' }"
                @click.prevent="redirctTo('#application_scenarios')" href="#application_scenarios"><span
                  class="iconfont">&#xe768;</span>应用场景</a></b-list-group-item>
            <b-list-group-item><a :class="{ active: active === '#contact_us' }" @click.prevent="redirctTo('#contact_us')"
                href="#contact_us"><span class="iconfont">&#xe608;</span>联系我们</a></b-list-group-item>
            <!-- @click.prevent="redirctTo('#search_order')"  -->
            <b-list-group-item v-if="h5Url"><a :class="{ active: active === '#search_order' }"
                :href="h5Url + '/#/pages_D/detail/index'"><span
                  class="iconfont">&#xe608;</span>订单查询</a></b-list-group-item>
          </b-list-group>
        </div>
      </div>
    </transition-group>
  </div>
  <WebPage v-else />
</template>

<script>
import MyFooter from "../components/MyFooter.vue";
import NavBar from "../components/NavBar.vue";
import WebPage from "./WebPage.vue";
import appInfo, { system, systemTitle, mapComputer } from '../utils/config.js';
import { mapState } from "vuex";

export default {
  name: "HomePage",
  components: {
    "nav-bar": NavBar,
    MyFooter,
    WebPage,
  },
  data () {
    return {
      email: "",
      visible: false,
      active: "",
      system,
      systemTitle,
      appInfo,
    };
  },
  computed: {
    ...mapState(["clientWidth"]),
    ...mapComputer(['appName', 'telephone', 'h5Url', 'mpUrl', 'company', 'icp', 'logo', 'useTitle', 'h5LogoStyle2'])
  },
  watch: {},
  mounted () {
    // setInterval(() => {
    //   this.visible = !this.visible;
    // }, 2000);
  },
  filters: {
    phoneFilter (val, chat) {
      let temp = val;
      return (
        temp.slice(0, 3) + chat + temp.slice(3, 7) + chat + temp.slice(7, 11)
      );
    },
  },
  methods: {
    redirctTo (elName) {
      this.active = elName;
      var target = document.querySelector(elName);
      console.log(target);
      target.scrollIntoView({ behavior: "smooth", block: "start" });
      setTimeout(() => {
        this.visible = false;
      }, 10);
    },
  },
};
</script>

<style scoped lang="less">
.page-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .jumbotron {
    background-color: #90c2ff;
    padding-bottom: 8rem;

    .app-title {
      margin: 2rem 0 0;
      font-size: 1.2rem;
      word-spacing: 0.2rem;
      letter-spacing: 0.2rem;
      color: #008bff;
      font-weight: 600;
    }

    .introduce-text1 {
      font-family: STXihei, "华文细黑", "Microsoft YaHei", "微软雅黑";
      font-size: 2rem;
      font-weight: 600;
      margin: 0.5rem 0;
    }

    .introduce-text2 {
      font-family: STXihei, "华文细黑", "Microsoft YaHei", "微软雅黑";
      letter-spacing: 0.001rem;
      letter-spacing: 0.001rem;
      font-size: 0.82rem;
      font-weight: 500;
      margin: 0.5rem 0 2rem;
      color: #347c8c;
    }

    .button {
      margin: 0 0.3rem;
      padding: 0.4rem 1.0rem;
    }

    .btn-secondary {
      background-color: #fff !important;
      color: #0b3a73 !important;
      border-color: #fff;
    }

    .bg-image01 {
      position: relative;
      width: 100%;
      height: 10rem;
      position: absolute;
      background-size: 100%, 100%;
      clip-path: polygon(50% 0, 80% 80%, 0% 80%, 0 0%);
      background-image: url("../assets/banner.9556eee1 01.png");

      .phone-graphic {
        position: absolute;
        top: 3.2rem;
        left: 10.2rem;
        font-size: 0.4rem;
        transform: rotate3d(100, 20, 300, 5deg);

        .text1 {
          color: #008bff;
        }

        .text2 {
          position: absolute;
          left: -0.24rem;
          top: 0.7rem;
          font-size: 0.2rem;
          line-height: 0.2rem;
          text-align: center;
          width: 3.2rem;
        }

        .text3 {
          position: absolute;
          left: -0.24rem;
          top: 1rem;
          font-size: 0.2rem;
          line-height: 0.2rem;
          text-align: center;
          width: 3.2rem;
        }
      }
    }
  }

  .drawer {
    display: flex;
    justify-content: space-between;
    z-index: 9999;
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;

    .mask {
      // width: 30vw;
      flex: 1;
      height: 100vh;
      background-color: #000;
      opacity: 0.5;
      filter: Alpha(opacity=50);
    }

    .context {
      display: flex;
      width: 16.5rem;
      max-width: 262.5px;
      flex-direction: column;
      height: 100vh;
      background-color: #fff;
    }
  }
}

.display-3 {
  margin-top: 56px;
}

.form-group {
  padding: 26px;
}

h3 {
  font-size: 1.75rem !important;
  text-align: left !important;
}

h2 {
  font-size: 2rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.text-center {
  text-align: center !important;
  margin-bottom: 1rem;
}

.in-center {
  text-align: center !important;
}

.list-group-item {
  span {
    margin-right: 0.4rem;
  }

  a {
    color: #333;
    text-decoration: none;
  }

  .active {
    color: #007bff;
  }
}

/* 自定义第一组样式，来控制 transition 内部的元素实现动画 */
/* v-enter 【这是一个时间点】 是进入之前，元素的起始状态，此时还没有开始进入 */
/* v-leave-to 【这是一个时间点】 是动画离开之后，离开的终止状态，此时，元素 动画已经结束了 */
// .v-enter,
// .v-leave-to {
//   opacity: 0;
//   // transform: translateX(150px);
// }

/* v-enter-active 【入场动画的时间段】 */
/* v-leave-active 【离场动画的时间段】 */
// .v-enter-active,
// .v-leave-active {
//   transition: all 0.8s ease;
//   // transform: translateX(150px);
// }

/* 自定义第二组样式，来控制 transition 内部的元素实现动画。这次，我们通过自己起的别名`name`来作为指令 */
// .my-enter,
// .my-leave-to{
//   // opacity: 0;
//   transform: translateX(350px);
// }

// .my-enter .mask {
//   transform: scale(1) translateX(-350px);
// }
// .my-enter-active .mask {
//   transform: scale(1) translateX(0px);
// }

// .my-leave-active .mask {
//   // transform: scale(0);
// }
// .my-leave-to .mask {
//   transform: scale(0) ;
// }

// .my-enter-active ,
// .my-leave-active{
//   transition: all 0.5s ease-in;
//   // transform: translateX(0px);
// }

/* 自定义第二组样式，来控制 transition 内部的元素实现动画。这次，我们通过自己起的别名`name`来作为指令 */
// .my2-enter,
// .my2-leave-to {
//   transform:  translateX(550px);
// }

// .my2-enter-active,
// .my2-leave-active {
//   transition: all 10.5s ease-in;
// }
</style>
