<template>
  <div>
    <b-navbar type="light" fixed="top" class="right" :style="navStyle">
      <b-img v-if="system === 0" src="logo.jpg" style="height: 100%; position: absolute" fluid alt="Fluid image">
      </b-img>
      <b-img v-if="system === 1" src="logo2.png" style="height: 100%; position: absolute" fluid alt="Fluid image">
      </b-img>
      <b-img v-if="system === 2" src="logo3.png" style="height: 100%; position: absolute" fluid alt="Fluid image">
      </b-img>
      <b-img v-if="system === 3" src="logo4.png" style="height: 100%; position: absolute" fluid alt="Fluid image">
      </b-img>
      <b-img v-if="system === 4" src="logo5.png" style="height: 100%; position: absolute" fluid alt="Fluid image">
      </b-img>
      <b-img v-if="system === 5" src="logo6.png" style="height: 100%; position: absolute" fluid alt="Fluid image">
      </b-img>
      <b-img v-if="system === 6" src="https://hx-1323651380.cos.ap-shanghai.myqcloud.com/logl.jpg"
        style="height: 100%; position: absolute" fluid alt="Fluid image">
      </b-img>
      <b-img v-if="system === 7" src="logo8.jpg" style="height: 100%; position: absolute;left: 0.5rem;" fluid
        alt="Fluid image">
      </b-img>
      <b-img v-if="system === 8" src="logo9.png" style="height: 100%; position: absolute;left: 1.8rem;" fluid
        alt="Fluid image">
      </b-img>
      <b-img v-if="system === 9" src="logo10.png" style="height: 100%; position: absolute;left: 1.6rem;" fluid
        alt="Fluid image">
      </b-img>
      <b-img v-if="system === 11" src="logo12.png" style="height: 100%; position: absolute;left: 1.6rem;" fluid
        alt="Fluid image">
      </b-img>
      <b-img v-else :src="logo" style="position: absolute;" :style="h5LogoStyle1" fluid alt="Fluid image">
      </b-img>
      <template v-if="useTitle">
        <b-navbar-brand to="/home" class="mx-3" v-if="system === 0">{{ appName }}企业{{
          systemTitle
        }}</b-navbar-brand>
        <b-navbar-brand to="/home" class="mx-3" v-else style="padding-left:58px">{{ appName }}{{
          systemTitle
        }}</b-navbar-brand>
      </template>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <template v-if="!hasLogin && false">
          <!-- <b-nav-item :to="{ name: 'login' }"> 登陆 </b-nav-item> -->
          <b-nav-item @click="loginCard"> 登陆 </b-nav-item>
          <b-button :to="{ name: 'signup' }" variant="primary"> 注册 </b-button>
        </template>

        <!--  已经登录  显示用户名以及跳转到主页按钮 -->
        <template v-else-if="hasLogin || true">
          <b-button variant="primary" @click="hanldeNavClick">
            <b-icon icon="list"></b-icon>
          </b-button>
        </template>
      </b-navbar-nav>

      <b-modal id="login-modal">
        <login />
      </b-modal>
    </b-navbar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import login from "../views/UserLogin.vue";
import appInfo, { system, systemTitle, mapComputer } from '../utils/config.js';
export default {
  components: {
    login,
  },
  name: "NavBar",
  data () {
    return {
      system,
      systemTitle,
      appInfo,
      email: "",
      visible: false,
      active: "",

    };
  },
  computed: {
    ...mapState(["user"]),
    ...mapComputer(['appName', 'telephone', 'h5Url', 'mpUrl', 'company', 'icp', 'logo', 'useTitle', 'h5LogoStyle1', 'navStyle'])
    // hasLogin () {
    //   return this.user;
    // },
    // appName () {
    //   console.log("system", this.system);
    //   return this.appInfo[this.system].appName;
    // },
    // telephone () {
    //   return this.appInfo[this.system].telephone;
    // },
    // mpUrl () {
    //   return this.appInfo[this.system].mpUrl;
    // },
    // useTitle () {
    //   return this.appInfo[this.system].useTitle;
    // }
  },
  mounted () { },
  methods: {
    loginCard () {
      this.$root.$emit("bv::show::modal", "login-modal");
    },
    hanldeNavClick () {
      this.$emit("hanldeNavClick");
    },
    redirctTo () { },
  },
};
</script>

<style scoped lang="less">
.right {
  background: #fff;
  justify-content: space-between;
}
</style>
